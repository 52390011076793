.navigation-bar {
  position: relative;
  z-index: 5;
  width: 100%;
  height: 76px;
  background: #0b1e32;

  display: flex;
  justify-content: space-between;

  &__overlay {
    position: fixed;
    z-index: 4;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }

  &__logo {
    width: 184px;

    @media only screen and (max-width: 1000px) {
      width: 100px;
    }
  }

  &__button-group {
    margin: 0 24px;
    height: 100%;
    display: flex;
    align-items: center;

    @media only screen and (max-width: 1000px) {
      margin: 0 12px;
    }
  }

  &__button {
    height: 100%;
    color: #fff;
    text-align: center;
    font-family: 'Inter';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 24px */
    letter-spacing: -0.176px;
    cursor: pointer;
    margin: 0 20px;

    @media only screen and (max-width: 1000px) {
      margin: 0 10px;
      font-size: 14px;
    }

    &--small {
      min-width: 26px;
    }

    &:focus {
      border: none;
    }

    &--selected {
      font-weight: 700;
      border-bottom: 3px solid white;

      &:focus {
        border: none;
        border-bottom: 3px solid white;
      }
    }
  }
}
