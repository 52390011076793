.send-nontaxable-emails-modal {
  background-color: white;
  width: 726px;
  border-radius: 6px;
  overflow: hidden;
  border: 1px solid #979797;

  & .loading-dots-container {
    display: block;
  }

  &__modal-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    padding: 24px;
  }

  &__message {
    color: black;
    text-align: center;
    font-size: 24px;
    display: block;
  }
  
  &__confirm-button {
    border-radius: 5px;
    border: 1px solid #69dbdb;
    background: #059f9f;
    color: white;
    font-size: 20px;
    width: 220px;
    height: 48px;
    display: block;
  }

  &__go-back-button {
    color: #1e4b75;
    font-size: 20px;
    display: block;
  }
}
