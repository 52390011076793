.error-message-container{
    position:relative;
    min-height: 2.75em;
    height: auto;
    width:100%;
    padding-left: 0.875em;
    margin-top:1rem;
    display:flex;
    align-items: center;
}

.error-message-text{
    color: rgb(255, 255, 255);
    font-family: 'Barlow-SemiBold','Barlow';
    font-size:  1em;
    font-weight: 600;    
    letter-spacing: -0.39px;
    word-break: break-all;
   
}