.base-page {
  background: linear-gradient(180deg, #0b1e32 0%, #1b1d21 100%);
  flex-grow: 1;
  overflow-y: auto;
  &-container {
    background: linear-gradient(180deg, #0b1e32 0%, #1b1d21 100%);
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
