.navigation-menu {
  background: #0b1e32;
  color: white;

  width: 100%;

  margin: 0;
  padding-left: 150px;
  max-height: 0;
  overflow: hidden;
  visibility: hidden;

  display: flex;
  flex-wrap: wrap;

  position: absolute;
  z-index: 5;
  transition: all 0.4s;

  &--open {
    padding: 40px 0 108px 150px;
    visibility: visible;
    max-height: 100vh;
  }

  &__category {
    margin: 0 40px 16px 0;

    &__label {
      color: #fff;
      font-family: 'Inter';
      font-size: 14px;
      font-style: normal;
      font-weight: 300;
      line-height: 150%; /* 24px */
      letter-spacing: -0.176px;
    }

    &__links {
      margin-top: 16px;
      margin-left: 16px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &__link {
        color: #fff;
        font-family: 'Inter';
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 27px */
        white-space: nowrap;

        margin-bottom: 8px;

        &:disabled {
          color: #ababab;
        }
      }
    }
  }
}
