.dashboard-card {
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: 40px;
  align-items: center;
  width: 300px;
  height: 64px;
  border-radius: 5px;
  background: #1e4b75;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  font-family: Barlow;
  font-weight: 400;
}
.dashboard-card:hover {
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  margin-left: 40px;
  align-items: center;
  width: 300px;
  height: 64px;
  border-radius: 5px;
  background: #359fb1;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  font-family: Barlow;
  font-weight: 400;
}
.dashboard-card-icon img {
  width: 35px;
  height: 40px;
  margin-right: 10px;
}
.dashboard-card-label {
  font-size: 16px;
  color: #ffffff;
  /*text-transform: uppercase;*/
}
.dashboard-card:hover {
  background-color: #359fb1;
  border-color: #359fb1;
}
