.access-denied-page {
  width: 100%;
  height: 100%;

  color: white;

  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;

  background-image: url('/images/boston-tinted.jpg');
  background-size: cover;
  background-position: center;

  &__header {
    z-index: 1;

    opacity: 0.5;
    color: #fff;
    text-align: right;
    font-family: 'Poppins';
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 78.188px; /* 122.168% */
    letter-spacing: -1.014px;

    margin-bottom: 8px;
  }

  &__message {
    color: #fff;
    text-align: right;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%; /* 27px */
    letter-spacing: -0.198px;

    z-index: 1;
    margin: 0;
    max-width: 472px;
  }
}
